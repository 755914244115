/*.inviz2023{overflow: hidden}*/
.s_content_wrap{position: fixed;width:100%;height:100vh;left:0;top:0;z-index: 0;background:#000}
section{display:block;background:#fff;position: relative;width:100%;margin-top:100vh;z-index: 9;min-height:700px}
section .content_box{height:100%;width:1400px;margin:0 auto;padding:50px 0 100px}

.s_visual{position:relative;display:flex;align-items: center;justify-content: center;height:100vh;flex-direction: column}
.s_visual h3{font-size:25px;color:#fff}
.s_visual h4{font-size:60px;color:#fff;margin:20px 0 ;font-family: GSans;height:75px;line-height:75px}
.s_visual p{font-size:17px;color:#fff;opacity: .6}
.s_visual p.scroll{position: absolute;bottom:50px;left:50%;margin-left:-25px}
.s_visual p.scroll span{left:10px;width:30px;height:30px;background:url(../../assets/img/sub/icon_arrow_down.svg) no-repeat 0 0 / 100%;display:block; position:absolute; animation:scrollDown 1.8s infinite;}

.s_visual video{position: absolute;z-index: -1;opacity: .5;object-fit: fill;}


@keyframes scrollDown {
    0% {
        top:15px;
    }
    50% {
        top:40px;
        opacity:1;
    }
    100% {
        top:15px;
    }
}
.h4_tit{position:relative;font-size:26px;color: #000000;font-weight:700;margin:50px 0 20px}
.h4_tit:after{width:10px;height:10px;border-radius:50%;content:'';position: absolute;left:0;top:-20px;background:#ff7520}
.h5_tit{position:relative;font-size:19px;color: #000000;font-weight:700;margin:50px 0 0}
.h5_tit2{position:relative;font-size:17px;color: #000000;font-weight:700;margin:30px 0 20px}

.text_type1{line-height:22px}

.flexbox{display:flex}

/* ul */
.ul_type1 li{font-size:15px;padding:20px;line-height:24px;background:#f0f0f0;margin-bottom:5px;color:#333}
.ul_type2 li{position:relative;font-size:14px;padding:5px 0 5px 20px;line-height:24px;margin-bottom:5px}
.ul_type2 li:before{content:'';position: absolute;left:0;top:15px;width:8px;height:1px;background: #cbcbcb}
.ul_type3{display:flex;}
.ul_type3 li{font-size:15px;padding:20px;line-height:24px;background:#f0f0f0;margin-bottom:5px;color:#333;width:24%;margin-right:1%}

.s_content_wrap .location {margin-top:70px; z-index:2; display:flex; justify-content:center; align-items:center; flex-wrap:wrap; gap:20px 0; }
.s_content_wrap .location i { font-size:15px; color:#fff; margin-right:20px; background-color: #ff7520; border-radius:50px; width:40px; height:40px; display:flex; justify-content:center; align-items:center; }
.s_content_wrap .location i svg{width:17px;height:17px;fill:#fff}
.s_content_wrap .location > .txt { position:relative; padding:0 20px; font-size:18px; color:#fff; font-weight:400; text-align:left; text-transform:uppercase; }
.s_content_wrap .location > .txt.on { z-index:999; }
.s_content_wrap .location > .txt:before { content:""; display:block; position:absolute; top:50%; transform:translateY(-50%); left:0; width:1px; height:12px; background-color:rgba(255,255,255,0.2); }
.s_content_wrap .location > .txt > span { cursor:pointer; position:relative; display:inline-block; width:100%; z-index:99; padding:18px 20px 18px 0;background:url(../../assets/img/sub/icon_arrow_down.svg) no-repeat center right / 20px}
.s_content_wrap .location > .txt > span:after { content:""; display:block;  width:9px; height:5px; position:absolute; right:0; top:50%; margin-top:-2px; transform:translateY(calc(-50% - 0.5px)) rotate(0); transition:all 0.3s ease; }
.s_content_wrap .location > .txt > span:before { content:""; display:block; position:absolute; bottom:0; left:0; width:100%; height:1px; background-color:rgba(255,255,255,0.5); }
.s_content_wrap .location > .txt > span.ov:after { content:""; transform:translateY(calc(-50% - 0.5px)) rotate(180deg); }
.s_content_wrap .location > .txt > span.ov { color:#fff; font-weight:600; }
.s_content_wrap .location > .box1,
.s_content_wrap .location > .box2 { width:240px; }
.s_content_wrap .location > .box3.d3 { display:none}
.s_content_wrap .location .submenu_box {z-index:999;text-align:left; position:absolute; left:50%; top:53px; width:100%; margin-left:0; transform:translateX(-50%); box-shadow:1px 1px 3px rgba(0,0,0,0.1); }
.s_content_wrap .location .submenu_box.subm3 { width:100%; }
.s_content_wrap .location .submenu_box ul { padding:20px 10px 20px 20px; background-color:#fff; height:auto !important; }
.s_content_wrap .location .submenu_box ul li:not(:last-child) { margin-bottom:15px; }
.s_content_wrap .location .submenu_box ul li{ color:#555; font-size:17px; font-weight:400; }
.s_content_wrap .location .submenu_box ul li:hover { color:#ff7520; font-weight:700; }
.s_content_wrap .location .submenu_box ul li .depth3 { display:none }
.s_content_wrap .location .submenu_box.gnb .subDepth { display:none  }
.s_content_wrap .location  .privacy { display:none; }


.content_box{display:flex;flex-wrap: wrap;}
.sub_conttit{width:400px;height:100%;position: sticky;align-self: flex-start;left:0;top:0;padding-top:70px;}
.sub_conttit .depth1_menu{color: #9a9a9a;font-weight:400;font-size:14px;font-family: GSans; margin-bottom:10px}
.sub_conttit .depth2_menu{color:#000;font-size:36px;font-family: GSans}
.sub_conttit ul{margin-top:70px}
.sub_conttit ul li{margin-bottom:25px}
.sub_conttit ul li a{position:relative;font-size:20px;color:#ccc;font-weight:700;text-decoration: none}
.sub_conttit ul li a.active{font-size:30px;color:#111;font-weight:700;}
.sub_conttit ul li a:after{margin-left:20px;opacity:.2;content:'';position: absolute;transition: all 0.3s ease-in-out;width:20px;height:20px;background:url(../../assets/img/sub/icon_next.svg) no-repeat 0 0 / 100%;}
.sub_conttit ul li a:hover:after{opacity:1;margin-left:40px}
.sub_conttit ul li a.active:after{opacity:1}
/* .sub_conttit ul li a{position:relative;font-size:20px;color:#ccc;font-weight:700;text-decoration: none}
.sub_conttit ul li a.active{font-size:30px;color:#111;font-weight:700;}
.sub_conttit ul li a:after{margin-left:20px;opacity:.2;content:'';position: absolute;transition: all 0.3s ease-in-out;width:20px;height:20px;background:url(../../assets/img/sub/icon_next.svg) no-repeat 0 0 / 100%;}
.sub_conttit ul li a:hover:after{opacity:1;margin-left:40px}
.sub_conttit ul li a.active:after{opacity:1} */

.sticky {position: sticky;top:10px;}

/* sub footer */
.s_footer{padding:50px 30px;display:flex;justify-content:space-between;height:180px;margin:0 auto;border-top:1px solid #ddd}
.footer_l{display:flex;}
.footer_l dl{margin-right:30px;color:#333}
.footer_l dl dt{margin-bottom:10px;font-weight:700}
.footer_l dl dd{line-height:20px}
.footer_r li{margin-bottom:5px;text-align:right}
.footer_r li a{padding:0 10px;cursor:pointer}
.footer_r .privacy{color: #e02525;font-weight:700}

.foot_modal_background{position: fixed;top: 0;left: 0;display: flex; align-items: center;justify-content: center;  width: 100vw;height: 100vh;background: #00000033;z-index: 999;}
/* .foot_modal{border:1px solid #ddd;width:700px;height:800px;position: fixed;left:50%;margin-left:-350px;top:120px;background: #fff;padding:20px 10px; z-index: 100;} */
.foot_modal{border:1px solid #ddd;width:700px;height:800px;position: fixed;background: #fff;padding:20px 10px; z-index: 100;}
.foot_modal .modal_tit {background: #fff;display:flex;justify-content: flex-end;height:40px;align-items: flex-start;}
.foot_modal .modal_tit h2{color:#333}
.foot_modal .modal_tit button{background:none}
.foot_modal .modal_tit svg{width:22px;height:22px}
.foot_modal .modal_cont{overflow-y: scroll;height:calc(100% - 40px)}
.foot_modal .modal_cont h2{position: absolute;top:20px;color:#333}

/*이메일무단수집거부*/
.emailno_box{line-height:30px;width:100%;border:1px solid #ddd;padding:30px 30px 30px 150px;background:url(../../assets/img/sub/icon_emailno.png) 20px center no-repeat;margin-bottom:50px;}


/* contents */
.content{width:calc(100% - 400px);min-height:500px;padding-top:70px}

.top_sumry{font-size:21px;line-height:33px;padding:0;color:#000;margin-bottom:70px;font-weight:400}

.dl_type1 dl dt{font-size:19px;font-weight:700;color:#333;padding:20px 0 10px}
.dl_type1 dl dd{font-size:15px;font-weight:400;margin-bottom:30px;line-height:25px}

.table_type1 table{text-align: center;border-top: 1px solid #7e869b;border-bottom: 1px solid #b5b9c4;margin: 4px 0 30px 0;font-size:13px;background:#fff;table-layout:inherit}
.table_type1 td.ln_bold,.table_type1 th.ln_bold {border-right:1px solid #c6cbd0 !important}
.table_type1 td.ln_color {background:#e9eff3}
.table_type1 th,.table_type1 td{border-left: 1px dotted #e2e2e2;border-bottom: 1px dotted #e2e2e2;border-right: 1px dotted #e2e2e2}
.table_type1 th{background-color: #f4f3f3;color: #494949;padding: 12px 8px}
.table_type1 tbody th{background:#f9f9f9;font-weight:700}
.table_type1 td{color: #797979;padding:12px;line-height:160%}
.table_type1 td a{color:#000}
.table_type1 td a:hover{color:#000}
.table_type1 td strong{color:#000}
.table_type1 td span{color:#000}
.table_type1 td span.redpoint{color:#c40101 !important;font-weight:600}

.img_box01 {margin-top:30px;border:1px solid #ddd;box-sizing: border-box;padding:20px;margin-bottom:30px;text-align:center}
.img_box01 img{max-width: 100%;}
.img_box02{border:1px solid #ddd;box-sizing: border-box;margin-bottom:30px;display:flex;flex-wrap: wrap}
.img_box02 div{width:48%;margin:1%;text-align:center}
.img_box02 div img{max-width: 100%}
.img_box02 div p{font-size:17px;font-weight:700;color:#000;margin:15px 0}

    /* 기업개요 */
.intro_top{margin-bottom:50px;position: relative;padding:35px 0 35px 50px;border-left:7px solid #000;font-size:20px;line-height:30px}
.intro_smry{background:#f9f9f9;padding:70px 30px;position: relative}
.intro_smry ul{display:flex;}
.intro_smry ul li{width:25%;text-align:center;padding-top:90px}
.intro_smry ul li.item01{background: url(../../assets/img/sub/icon_itm01.svg) no-repeat center top / 60px}
.intro_smry ul li.item02{background: url(../../assets/img/sub/icon_itm02.svg) no-repeat center top / 60px}
.intro_smry ul li.item03{background: url(../../assets/img/sub/icon_itm03.svg) no-repeat center top / 60px}
.intro_smry ul li.item04{background: url(../../assets/img/sub/icon_itm04.svg) no-repeat center top / 60px}
.intro_smry ul li.item05{background: url(../../assets/img/sub/icon_itm05.svg) no-repeat center top / 60px}
.intro_smry ul li.item06{background: url(../../assets/img/sub/icon_itm06.svg) no-repeat center top / 55px}
.intro_smry ul li dd{height:50px;font-size:22px;font-weight:700;color:#ec7d2c;margin:10px 0 20px}
.intro_smry ul li dd.type2{font-size:15px}
.intro_smry ul li a{margin:0 auto;display:block;background:#333;color:#fff;width:130px;height:32px;line-height:32px;}
/* .intro_smry:after{z-index:-1;position: absolute;top:0;right:-300px;width:500px;background:#f9f9f9;content:'';height:358px} */
.intro_smry:after{z-index:-1;position: absolute;top:0;right:-300px;background:#f9f9f9;content:'';height:358px}
.intro_smry .dw_btn{color:#fff;font-size:15px;background:#ec7d2c url(../../assets/img/sub/icon_dw.svg) no-repeat 95% center/ 30px;padding:25px;width:300px;position: absolute;right:0;bottom:-35px}

.intro_txt ul{display:flex;margin-top:150px;}
.intro_txt ul li{width:33%;text-align:left;padding:0 20px;line-height:20px;}
.intro_txt ul li span{text-align:center;color:#000;display:block;font-size:24px;font-weight:200;line-height:35px;margin-bottom:20px}

/* mission */
.mission_wrap{text-align:center;margin-top:100px;background:#f2f2f2;padding:0 30px 30px 30px;border-radius:20px}
.mission_wrap h3{position:relative;top:-20px;font-weight:500;font-size:16px;color:#fff;background:#ec7d2c;padding:10px 40px;border-radius:50px;display:inline-block}
.mission_wrap .mission{font-size:16px;font-weight:500;color:#000;margin-bottom:50px}
.mission_wrap .mission span{font-size:30px;display:block;padding:20px 0 10px;font-weight:700}
.mission_wrap ul {display:flex}
.mission_wrap ul li{width:23%;margin:0 1%;padding:100px 0;color:#fff;border-radius:10px}
.mission_wrap ul li span{display:block;font-size:17px;margin-bottom:10px}
.mission_wrap ul li.mission01{background: #606060 url(../../assets/img/sub/mission_01.png) no-repeat center center / cover}
.mission_wrap ul li.mission02{background: #606060 url(../../assets/img/sub/mission_02.png) no-repeat center center / cover}
.mission_wrap ul li.mission03{background: #606060 url(../../assets/img/sub/mission_03.png) no-repeat center center / cover}
.mission_wrap ul li.mission04{background: #606060 url(../../assets/img/sub/mission_04.png) no-repeat center center / cover}

.goal_wrap{text-align:center;margin-top:100px;background:url(../../assets/img/sub/goal_arrow.png) no-repeat center 160px / 180px}
.goal_wrap h3{font-weight:500;font-size:16px;color:#fff;background:#ec7d2c;padding:10px 40px;border-radius:50px;display:inline-block}
.goal_wrap .goal{font-size:28px;font-weight:700;color:#000;margin:30px 0}
.goal_wrap .goal2{font-size:17px;font-weight:500;color: #737373;margin-bottom:50px}

.vision_wrap{text-align:center}
.vision_wrap h3{position:relative;font-weight:700;font-size:22px;color:#ec7d2c;padding:10px 40px;border-radius:50px;display:inline-block}
.vision_wrap h3:before{width:200px;height:4px;background:#ec7d2c;content:'';position: absolute;left:-200px;top:20px}
.vision_wrap h3:after{width:200px;height:4px;background:#ec7d2c;content:'';position: absolute;right:-200px;top:20px}
.vision_wrap .vision{font-size:17px;font-weight:700;color:#000;margin-bottom:50px}
.vision_wrap .vision span{font-size:37px;display:block;padding:20px 0 10px}

/****** 연혁 ******/
.section01_tit{font-size:40px;color:#000;font-weight:700;line-height:60px}
.history{display: flex;justify-content: space-between;margin-bottom:100px;position: relative}
.history .tit_box{text-align:center;}
.history .tit_box span.text{display:block;}
.history .tit_box .sub_tit>span.circle3{margin-right:0;}
.history .tit_box .sub_tit{margin-bottom: 13px;}
.history .history_box{margin-top:108px;display:flex;justify-content: space-between;margin-bottom:112px;}
.history_year{font-size:100px;font-weight:900;color: #f1f1f1;position: absolute;left:0;top:0}
.biz_cont{position:relative;margin-top:47px;width:100%}
.history::after{position:absolute;left:50%;top:13px;height: 100%;content:'';display:block;clear:both;background: #efefef;width: 1px;}
.biz_box ul li:last-child .biz_wrap::after{display:none;}
.biz_box ul li{width:50%;left:49.4%;position: relative}
.biz_box ul li:nth-child(2n){left:0}
.biz_box ul li:nth-child(2n) .biz_num::before{right:-12px;left:auto}
.biz_box ul li:nth-child(2n) .biz_num{text-align:right}
.biz_box ul li:nth-child(2n) .biz_txt{text-align:right;padding:40px 20px 0 0}
.biz_wrap{display:flex;position:relative;padding-bottom:50px;word-break: keep-all;flex-direction: column}
.biz_num{padding-top:4px;margin-left:40px;margin-right: 30px;font-size:30px;font-weight:700;color: #221e1f;line-height:1;}
.biz_num::before{content:'';clear:both;display:block;position:absolute;left:-5px;top:8px;width:25px;height:25px;border-radius:10px 0 10px 0;z-index:5;background-color: #ffaf26;}
.biz_txt {padding:40px 0 0 20px}
.biz_txt span{display:block;color: #221e1f;font-size:16px;margin-bottom:18px;position:relative;padding-left: 7px;line-height: 27px;}
.biz_txt span p{width:30px;font-size:20px;font-weight:800;margin:0 15px;color: #000000;display:inline-block}

/* 사업영역 파트너*/
.area_box {display:flex;;margin-bottom:50px}
.area_box > div{width:50%}
.area_box > div img{max-width: 100%;}
.area_box .txt{padding:70px 30px;}
.area_box .txt h3{font-size:20px;color:#000;margin-bottom:30px}

.partner_cont ul{ display:flex;flex-wrap: wrap}
.partner_cont ul li{width:24%;border:1px solid #ddd;margin:.5%;text-align:center;display:flex;align-items: center;justify-content: center;height:140px}
.partner_cont ul li img{width:60%;}

/* ceo */
.ceo_wrap {margin:0 auto;font-size:18px}
.ceo_wrap .tit{font-size:40px;font-weight:800;color:#000;line-height:60px;margin-bottom:50px}
.ceo_wrap .sign{margin-top:50px;width:275px;height:83px;display:block;background:url(../../assets/img/sub/sign.png) no-repeat 0 0}
.ceo_wrap p{line-height:27px}

/* 오시는길 */
.contact_wrap .map{background:#333;width:100%;height:500px;margin-bottom:50px}
.contact_wrap ul {display:flex;}
.contact_wrap li{position: relative;padding:5px 10px 5px 25px;line-height:30px;font-size:16px}
.contact_wrap li:before{content:'';position: absolute;left:0;top:0;width:30px;height:30px}
.contact_wrap li.add{background:url(../../assets/img/main/icon_address.png) no-repeat left 10px;margin-right:100px}
.contact_wrap li.add span:hover{text-decoration: underline; font-weight: bold;}
.contact_wrap li.tel{background:url(../../assets/img/main/icon_call.png) no-repeat left 10px}

/* 어노테이션 */
.labeling_cont{display:flex;flex-wrap: wrap}
.labeling_cont > div{width:49%;border:1px solid #ddd;padding:30px 15px;margin:0.5%;display:flex;flex-direction: column;justify-content: space-between}
.labeling_cont div h4{font-size:17px;color:#000;margin-bottom:20px}
.labeling_cont div p{font-size:15px;line-height:22px;margin-bottom:20px}
.labeling_cont div img{max-width: 100%;}

/* 안면인식 */
.facial_box{background:url(../../assets/img/sub/facial_02.png) no-repeat left top / 440px;padding-left:450px}

/* 유전자 */
.gene_process ul{display:flex}
.gene_process ul li{position:relative;line-height:22px;width:30%;text-align:center;border:1px solid #ddd;border-radius:10px;margin:0 1%;padding:120px 20px 50px}
.gene_process ul li span{display:block;font-size:18px;margin-bottom:10px;font-weight:600;color:#000}
.gene_process ul li:after{position: absolute;content:'';top:45%;right:-25px;border-left:10px solid #ddd;border-top:10px solid transparent;border-right:10px solid transparent;border-bottom:10px solid transparent;}
.gene_process ul li:last-child:after{display:none}
.gene_process ul li.step01{background:url(../../assets/img/sub/gene_icon01.png) no-repeat center 25px / 70px}
.gene_process ul li.step02{background:url(../../assets/img/sub/gene_icon02.png) no-repeat center 25px / 70px}
.gene_process ul li.step03{background:url(../../assets/img/sub/gene_icon03.png) no-repeat center 25px / 70px}


/* remote service */
.rs_box{margin-bottom:70px;padding-bottom:70px;border-bottom:1px solid #ddd;display:flex;justify-content: space-between;align-items: flex-end}
.rs_box h3{font-size:30px;font-weight:800;margin-bottom:15px;color:#000}
.rs_box p{margin-top:30px;font-size:17px;font-weight:300}
.rs_box p.type2{margin-top:7px;font-size:13px;font-weight:300}
.rs_box p span{font-weight:900;color:#333;display:inline-block;margin-left:10px}
.rs_ul li{overflow:hidden;width:100%;}
.rs_ul img{float:left;margin:0 30px 20px 0}
.rs_ul p{height:420px;padding:150px 0 0 10px;font-size:18px;line-height:30px}
.rs_ul p span{margin-top:20px;margin-bottom:30px;font-size:60px;color:#ddd;font-weight:900;display:block;font-style: italic;letter-spacing: -2px}

.btn_site{height:50px;font-size:15px;margin-bottom:2px;width:360px;display:block;padding:0 50px 0 10px;line-height:50px;background: #ea930b url(../img/sub/arrow_tabnav_on.png) 94% center no-repeat;color:#fff}
.btn_site:hover{background-color: #ea9f2a;text-decoration:none}
.btn_dw{height:50px;width:400px;display:block;padding:0 70px 0 10px;line-height:50px;background: #7e48b2 url(../img/sub/arrow_tabnav_on.png) 94% center no-repeat;color:#fff}
.btn_dw:hover{background-color: #9464c2;text-decoration:none}



/* mobile */
@media (max-width: 630px) {
    .s_visual{height:45vh;padding:0 20px}
    .s_visual h3{display:none}
    .s_visual h4{font-size:30px;margin:0}
    .s_visual p{text-align:center}
    .s_visual video{width:fit-content}
    .s_visual p.scroll{display:none}

    section{padding:0 10px;margin-top:45vh}
    section .content_box{width:100%}

    .s_content_wrap{height:70vh}
    .s_content_wrap .location{display:none}

    .content{width:100%}
    .sub_conttit .depth2_menu{font-size:28px}
    .sub_conttit{position: relative;padding:0;width:100%;text-align:center}
    .sub_conttit ul{display:flex;align-items: center;margin:30px 0 0 0;justify-content: center;}
    .sub_conttit ul li{text-align:center;padding:0 10px}
    .sub_conttit ul li a{font-size:14px}
    .sub_conttit ul li a.active{font-size:16px}
    .sub_conttit ul li a:after{ transform: rotate(90deg);left:50%;margin-left:-10px !important;;bottom:-30px}
    /*.sub_conttit ul li a:hover:after{margin:0 !important;}*/

    .s_footer{flex-direction: column;height:auto;padding:30px 0}
    .footer_l{flex-direction: column}
    .footer_l dl{margin:0 0 20px 0}
    .footer_r li{text-align:left}
    .footer_r .privacy{padding: 0;margin-right:10px}
    .top_sumry{font-size:16px}


    section .content_box{padding:50px 0}
    .ul_type3{flex-direction: column}
    .ul_type3 li{width:100%}
    .table_type1 {overflow-x: scroll}

    .img_box02{flex-direction: column}
    .img_box02 div{width:100%;margin:0 0 20px;padding:10px}
    /* 회사소개 */
    /*기업개요*/
    .intro_top{font-size:13px;line-height:24px;padding:20px 0 20px 20px}

    .intro_smry{padding:70px 30px 0}
    .intro_smry ul{flex-wrap: wrap;}
    .intro_smry ul li{width:50%;margin-bottom:30px}
    .intro_smry ul li dd{font-size:13px}
    .intro_smry ul li dd.type2{font-size:13px}
    .intro_smry:after{display:none}
    .intro_smry .dw_btn{width:100%}

    .intro_txt ul{flex-direction: column}
    .intro_txt ul li{width:100%;margin-bottom:30px}

    /*목표*/
    .vision_wrap .vision{font-size:13px}
    .vision_wrap .vision span{font-size:18px}
    .mission_wrap{padding:0 15px 15px}
    .mission_wrap .mission span{font-size:20px}
    .mission_wrap ul{flex-wrap: wrap}
    .mission_wrap ul li{width:48%;margin:1%;padding:40px 0}
    .vision_wrap h3:before{width:60px;left:-60px}
    .vision_wrap h3:after{width:60px;right:-60px}
    .goal_wrap .goal{font-size:22px}
    .goal_wrap .goal2{font-size:15px}
    .goal_wrap{background-size:130px;background-position: center 200px}

    /*연혁*/
    .history::after{display:none}
    .biz_wrap{padding-bottom:20px}
    .biz_box ul li{width:100%;left:0}
    .biz_box ul li:nth-child(2n) .biz_num{text-align:left}
    .biz_box ul li:nth-child(2n) .biz_num::before{right:auto;left:0}
    .biz_box ul li:nth-child(2n) .biz_txt{text-align:left}
    .biz_txt{padding:25px 0}
    .biz_txt span p{position: absolute;left:0;top:0;margin:0 5px}
    .biz_txt span{padding-left:50px;}

    /*사업영역*/
    .area_box{flex-direction: column}
    .area_box > div{width:100%}
    .area_box .txt{padding:30px 0}
    .partner_cont ul li{width:32%}
    .partner_cont ul li img{width:80%}
    /*인사말*/
    .ceo_wrap .tit{font-size:22px;line-height:35px}
    /*라벨링 */
    .labeling_cont > div{width:100%}

    /*감정분석*/
    .facial_box{padding:300px 0 0}

        /*유전바이오*/
    .gene_process ul{flex-direction: column}
    .gene_process ul li{width:100%;margin:0 0 20px}
    .gene_process ul li:after{top:auto;bottom:-25px;right:auto;left:48%;transform: rotate(90deg)}


        /* 원격지원 */
    .rs_box{flex-direction: column;align-items: flex-start}
    .btn_site{width:100%;margin-top:20px}
    .rs_ul img{max-width: 100%}
    .rs_ul p{height:auto;margin-bottom:50px}

    .foot_modal{width:100% ;left:0;}
}



/* tablet */
@media (min-width: 631px) and (max-width: 1024px){
    .s_visual{height:45vh;padding:0 20px}
    .s_visual h3{display:none}
    .s_visual h4{font-size:30px;margin:0}
    .s_visual p{text-align:center}
    .s_visual video{width:fit-content}
    .s_visual p.scroll{display:none}

    section{padding:0 10px;margin-top:45vh}
    section .content_box{width:100%}

    .s_content_wrap{height:70vh}
    .s_content_wrap .location{display:none}

    .content{width:100%}
    .sub_conttit .depth2_menu{font-size:28px}
    .sub_conttit{position: relative;padding:0;width:100%;text-align:center}
    .sub_conttit ul{display:flex;align-items: center;margin:30px 0 0 0;justify-content: center;}
    .sub_conttit ul li{text-align:center;padding:0 10px}
    .sub_conttit ul li a{font-size:14px}
    .sub_conttit ul li a.active{font-size:16px}
    .sub_conttit ul li a:after{ transform: rotate(90deg);left:50%;margin-left:-10px !important;;bottom:-30px}
    /*.sub_conttit ul li a:hover:after{margin:0 !important;}*/

    .s_footer{flex-direction: column;height:auto;padding:30px 0}
    .footer_l{flex-direction: column}
    .footer_l dl{margin:0 0 20px 0}
    .footer_r li{text-align:left}
    .footer_r .privacy{padding: 0;margin-right:10px}
    .top_sumry{font-size:16px}


    section .content_box{padding:50px 0}
    .table_type1 {overflow-x: scroll}

    .img_box02{flex-direction: column}
    .img_box02 div{width:100%;margin:0 0 20px;padding:10px}
    /* 회사소개 */
    /*기업개요*/
    .intro_top{font-size:13px;line-height:24px;padding:20px 0 20px 20px}

    .intro_smry{padding:70px 30px 0}
    .intro_smry ul{flex-wrap: wrap;}
    .intro_smry ul li{width:50%;margin-bottom:30px}
    .intro_smry ul li dd{font-size:13px}
    .intro_smry ul li dd.type2{font-size:13px}
    .intro_smry:after{display:none}
    .intro_smry .dw_btn{width:100%}

    .intro_txt ul{flex-direction: column}
    .intro_txt ul li{width:100%;margin-bottom:30px}

    /*목표*/
    .vision_wrap .vision{font-size:13px}
    .vision_wrap .vision span{font-size:18px}
    .mission_wrap{padding:0 15px 15px}
    .mission_wrap .mission span{font-size:20px}
    .mission_wrap ul{flex-wrap: wrap}
    .mission_wrap ul li{width:48%;margin:1%;padding:40px 0}
    .vision_wrap h3:before{width:60px;left:-60px}
    .vision_wrap h3:after{width:60px;right:-60px}
    .goal_wrap .goal{font-size:22px}
    .goal_wrap .goal2{font-size:15px}
    .goal_wrap{background-size:130px;background-position: center 200px}

    /*연혁*/
    .history::after{display:none}
    .biz_wrap{padding-bottom:20px}
    .biz_box ul li{width:100%;left:0}
    .biz_box ul li:nth-child(2n) .biz_num{text-align:left}
    .biz_box ul li:nth-child(2n) .biz_num::before{right:auto;left:0}
    .biz_box ul li:nth-child(2n) .biz_txt{text-align:left}
    .biz_txt{padding:25px 0}
    .biz_txt span p{position: absolute;left:0;top:0;margin:0 5px}
    .biz_txt span{padding-left:50px;}

    /*사업영역*/
    .area_box{flex-direction: column}
    .area_box > div{width:100%}
    .area_box .txt{padding:30px 0}
    /*인사말*/
    .ceo_wrap .tit{font-size:22px;line-height:35px}
    /*라벨링 */
    .labeling_cont > div{width:100%}

    /*감정분석*/
    .facial_box{padding:300px 0 0}



    /* 원격지원 */
    .rs_box{flex-direction: column;align-items: flex-start}
    .btn_site{width:100%;margin-top:20px}
    .rs_ul img{max-width: 100%}
    .rs_ul p{height:auto;margin-bottom:50px}

    .foot_modal{width:100% ;left:0;}

}


/* desktop */
@media (min-width: 1025px) and (max-width: 1500px) {

    .s_visual{height:45vh;padding:0 20px}
    .s_visual h3{display:none}
    .s_visual h4{font-size:30px;margin:0}
    .s_visual p{text-align:center}
    .s_visual video{width:fit-content}
    .s_visual p.scroll{display:none}

    section{padding:0 10px;margin-top:45vh}
    section .content_box{width:100%}

    .s_content_wrap{height:70vh}
    .s_content_wrap .location{display:none}

    .content{width:100%}
    .sub_conttit .depth2_menu{font-size:28px}
    .sub_conttit{position: relative;padding:0;width:100%;text-align:center}
    .sub_conttit ul{display:flex;align-items: center;margin:30px 0 0 0;justify-content: center;}
    .sub_conttit ul li{text-align:center;padding:0 20px}
    .sub_conttit ul li a{font-size:20px}
    .sub_conttit ul li a.active{font-size:22px}
    .sub_conttit ul li a:after{ transform: rotate(90deg);left:50%;margin-left:-10px !important;;bottom:-30px}
    /*.sub_conttit ul li a:hover:after{margin:0 !important;}*/

    .s_footer{flex-direction: column;height:auto;padding:30px 0}
    .footer_l{flex-direction: column}
    .footer_l dl{margin:0 0 20px 0}
    .footer_r li{text-align:left}
    .footer_r .privacy{padding: 0;margin-right:10px}
    .top_sumry{font-size:16px}


    section .content_box{padding:50px 0}
}
#loading_wrap {
    z-index: 99;
    position: absolute;
    width: 100%;
    height: 100%;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.ring {
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 150px;
    height: 150px;
    background: transparent;
    border: 3px solid #3c3c3c;
    border-radius: 50%;
    text-align: center;
    line-height: 150px;
    font-family: nexon2;
    font-weight: 700;
    font-size: 20px;
    color: #dadce4;
    letter-spacing: 4px;
    text-transform: uppercase;
    text-shadow: 0 0 10px #d1d1dc;
    box-shadow: 0 0 20px rgba(192, 192, 192, 0.5);
}

.ring:before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border: 2px solid transparent;
    border-top: 2px solid #2ee5db;
    border-right: 2px solid #2ee5db;
    border-radius: 50%;
    animation: animateLine 2s linear infinite;
}

.ring span {
    display: block;
    position: absolute;
    top: calc(50%);
    left: 50%;
    width: 50%;
    height: 4px;
    background: transparent;
    transform-origin: left;
    animation: animateBall 2s linear infinite;
}

.ring span:before {
    content: '';
    position: absolute;
    width: 16px;
    height: 16px;
    border-radius: 50%;
    background: #2ee5db;
    top: -6px;
    right: -8px;
    box-shadow: 0 0 20px #2ee5db;
}

.absolute {
    position: absolute;
    z-index: 10;
}

.relative {
    position: relative;
}

.loading_bg {
    width: 100%;
    height: 100%;
    position: fixed;
    background: #000;
    opacity: 0.5;
    z-index: 9;
    top: 0;
    left: 0;
}

.loading_text {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 140px;
    height: 150px;
    text-align: center;
    line-height: 150px;
    font-weight: 700;
    font-size: 15px;
    color: #dadce4;
    letter-spacing: 4px;
    text-transform: uppercase;
}

@keyframes animateLine {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}

@keyframes animateBall {
    0% {
        transform: rotate(45deg);
    }
    100% {
        transform: rotate(405deg);
    }
}
/*main*/
.inviz2023.en .visual_wrap .visual_txt .txtbox h3 em{font-size:18px}
.inviz2023.en .visual_wrap .visual_txt .txtbox h4{font-size:55px}
.inviz2023.en .visual_wrap .visual_txt .txtbox h4 span:nth-child(2) em{font-size:23px}

.inviz2023.en .main_solutionbox .txt em{font-size:16px}
.inviz2023.en .main_solutionbox .txt p{font-size:26px}
.inviz2023.en .main_solution ul li p{font-size:20px}
.inviz2023.en .main_titbox h4.h4_main{font-size:25px}

/*메뉴*/
.inviz2023.en .header_menu > ul > li > a{padding:20px 17px;font-size:14px;letter-spacing: -.5px}
.inviz2023.en .header_2depth > ul li a{font-size:16px !important}
.inviz2023.en .sub_conttit ul li a.active{font-size:21px}
/*헤더*/
.inviz2023.en .lang_btn .eng{opacity: 1}
.inviz2023.en .lang_btn .kr{opacity: .4}

/*서브공통*/
.inviz2023.en .s_visual h4{font-size:45px;font-family: inherit}
.inviz2023.en .intro_top{font-size:18px;line-height:28px}
.inviz2023.en .top_sumry{font-size:18px;line-height:28px}

.inviz2023.en .h4_tit{font-size:22px}
.inviz2023.en .ul_type1 li{font-size:14px}

.inviz2023.en .sub_conttit .depth2_menu{font-size:28px;font-family: inherit}


/*기업개요*/
.inviz2023.en .intro_txt ul li span{font-size:17px;line-height:22px}
.inviz2023.en .intro_smry ul li dd{font-size:18px}
.inviz2023.en .intro_smry ul li dd.type2{font-size:14px}

/*목표*/
.inviz2023.en .vision_wrap .vision span{font-size:28px}
.inviz2023.en .mission_wrap .mission span{font-size:24px}
.inviz2023.en .mission_wrap ul li{padding:100px 20px}
.inviz2023.en .goal_wrap .goal{font-size:24px}

/*연혁*/
.inviz2023.en .biz_txt span{font-size:13px}

/*ceo*/
.inviz2023.en .ceo_wrap .tit{font-size:27px;padding-right:30%;line-height:37px}

/*원격지원*/
.inviz2023.en .rs_box h3{font-size:22px}





/* mobile */
@media (max-width: 630px) {

    .inviz2023.en .s_visual h4{font-size:26px}
    .inviz2023.en .sub_conttit ul li a{font-size:14px}

    .all_menu .inner > ul > li > a{font-size:14px !important;}

    /*ceo*/
    .inviz2023.en .ceo_wrap .tit{font-size:22px;padding-right:0;line-height:32px}

}

@media (min-width: 631px) and (max-width: 1024px){
    .all_menu .inner > ul > li > a{font-size:14px !important;}

}














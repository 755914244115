.header_wrap {top: 0;position: fixed;display: flex;width: 100%;z-index: 99;background: rgba(49, 49, 49, 0.8);transition: background-color 0.3s;backdrop-filter: blur(5px);-webkit-backdrop-filter: blur(10px);}
.header_wrap:hover {background: #fff;color: #000}
.header_wrap:hover a {color: #000}
.header_wrap h1 {font-size: 0;position: absolute;left: 0;top: 0}
.header_wrap .logo {display: block;width: 150px;height: 60px;background: url(./assets/img/logo_wh.png) no-repeat 0 center / 120px}
.header_wrap:hover .logo {background: url(./assets/img/logo_co.png) no-repeat 0 center / 120px}
.header_wrap .login {background: none;width: 32px;height: 32px;display: block;text-align: center}
.header_wrap .login svg {fill: #fff;width: 32px;height: 32px}
.header_wrap .logout {background: none;}
.header_wrap .logout svg {background: none;width: 32px;height: 32px}
.header_wrap:hover .login svg {fill: #333}
.header_wrap .login_info{color:#fff;display:flex;align-items: center}
.header_wrap .login_info svg{fill:#fff;margin-left:10px;width:28px;height:28px}
.header_wrap:hover .login_info{color:#333}
.header_wrap:hover .login_info svg{fill:#333}
.header_wrap:hover button {color:#333}
.header_wrap ul li:last-child .header_2depth{display: none}
.header_wrap ul li a.link_active{color: #ff9627;font-weight:800}
.modal_wrap {position: fixed;width: 100%;height: 100%;background: rgba(0, 0, 0, .5);z-index: 99;left: 0;top: 0}
.login_modal {z-index: 99;position: absolute;box-shadow: 0 0 20px rgba(0, 0, 0, .1);padding: 70px 10px;border-radius: 20px;left: 50%;top: 280px;margin-left: -190px;width: 380px;height: 400px;background: #fff}
.login_modal h3 {font-size: 40px;font-weight: 700;color: #333;text-align: center;margin-bottom: 30px}
.login_modal .close {position: absolute;right: 10px;top: 10px;width: 30px;height: 30px;background: none}
.login_modal .close svg {width: 20px;height: 20px}
.login_modal input {border-bottom: 1px solid #ddd;width: 100%;margin-top: 20px}
.login_modal .login_form button {width: 100%;background: #8231af;color: #fff;height: 45px;line-height: 45px;margin-top: 20px}

header {display: flex !important;width: 1630px;margin: 0 auto;justify-content: space-between;align-items: center}
.header_menu ul {display: flex}
.header_menu ul li a {display: block;color: #fff;padding: 20px 35px;font-size: 16px}
.header_2depth {display: flex;position: absolute;height: 260px;color: #333;width: 100%;left: 0;justify-content: center;box-shadow: 0 20px 30px rgba(0, 0, 0, .1)}
.header_2depth > ul {position: relative;width: 59%;margin: 15px auto}
.header_2depth > ul li {display: inline-block;margin-right: 40px;position: relative}
.header_2depth > ul > li > a{font-weight:800 !important;font-size:19px !important;opacity: 1 !important;}
.header_2depth > ul li a {display: inline-block;color: #333;text-align: left;font-size: 18px;font-weight: 300;padding: 10px 0;opacity: .6}
.header_2depth > ul li a:hover {text-decoration: none;opacity: 1;font-weight: 400}
.header_2depth .bg {border-bottom: 1px solid #ddd;background: #fff;width: 100%;height: 260px;position: absolute;left: 0;top: 0;z-index: -1}
.header_2depth .img {width: 700px;height: 260px}
.header_2depth .img.m01 {background: url(./assets/img/sub/h_visual01.png) no-repeat left center}
.header_2depth .img.m02 {background: url(./assets/img/sub/h_visual02.png) no-repeat left center}
.header_2depth .img.m03 {background: url(./assets/img/sub/h_visual03.png) no-repeat left center}
.header_2depth .img.m04 {background: url(./assets/img/sub/h_visual04.png) no-repeat left center}
.header_2depth .img.m05 {background: url(./assets/img/sub/h_visual05.png) no-repeat left center}
.header_3depth {position: absolute;left: 0;top: 50px;width: 200px}
.header_3depth > ul {flex-direction: column}
.header_3depth > ul li a {font-size: 17px}
.header_right{display:flex}
.header_right > div{display:flex;margin-right:10px}
.header_right > div button{background:none;color:#fff;padding:10px}

.lang_btn .eng{opacity: .4}
.lang_btn .kr{opacity: 1}

/* 전체메뉴 */
.all_menu{display:none}

/* visual */
.visual_wrap {height: 100vh;overflow: hidden;position: relative}
.visual_wrap video {width: 100%}
.visual_wrap .visual_txt {position: absolute;width: 100%;height: 100%}
.visual_wrap .visual_txt .visual_txtinnerbox {overflow: hidden;position: absolute;left: 50%;margin-left: -815px;height: 350px;bottom: 15vh;z-index: 9;width: 1630px;}
.visual_wrap .visual_txt .txtbox h3 {display: block;overflow: hidden;}
/*.visual_wrap .visual_txt .txtbox h3 em{font-family:Pretendard;font-weight:300;display:block;animation:visual_text_motion2 1.5s cubic-bezier(0.4, 0, 0.2, 1) both;font-size:27px;color:#fff;margin-bottom:20px}*/
.visual_wrap .visual_txt .txtbox h3 em {margin-bottom: 30px;font-family: Pretendard;font-weight: 300;display: block;;font-size: 27px;color: #fff;transform: translateY(100%);}
.visual_wrap .visual_txt .swiper-slide-active .txtbox h3 em {transform: translateY(0);transition: transform 1.2s;}
.visual_wrap .visual_txt .txtbox h4 {font-size: 80px;color: #fff;font-family: GSans}
.visual_wrap .visual_txt .txtbox h4 span {display: block;overflow: hidden;font-family: GSans}
/*.visual_wrap .visual_txt .txtbox h4 span em{font-family:Pretendard;font-weight:800;display:block;animation: visual_text_motion 1.5s cubic-bezier(0.4, 0, 0.2, 1) both;}*/
.visual_wrap .visual_txt .txtbox h4 span em {font-family: Pretendard;font-weight: 800;display: block;transform: translateY(100%);}
.visual_wrap .visual_txt .swiper-slide-active .txtbox h4 span em {transform: translateY(0);transition: transform 1.5s;}
.visual_wrap .visual_txt .txtbox h4 span:nth-child(1) em {}
.visual_wrap .visual_txt .txtbox h4 span:nth-child(2) em {font-size: 42px}
.visual_wrap .visual_txt .txtbox h4 span:nth-child(3) em {font-size: 42px}

#timer {margin: 0px;width: 40px;height: 40px;position: relative;}
.visual_bar {height: 60px;display: flex;align-items: center;position: absolute;z-index: 10;width: 1640px;padding: 0 20px;max-width: 100%;bottom: -50px;left: 50%;transform: translate(-50%, -50%);}
.visual_bar .btn {background: none;position: absolute;left: 50%;top: 50%;transform: translate(-50%, -50%);}
.visual_bar .btn .pause {display: flex;justify-content: space-between;width: 10px;height: 12px;}
.visual_bar .btn .pause span {width: 3px;height: 100%;background-color: #fff;display: block;border-radius: 5px;}
.visual_bar .btn .off {display: none;}
.visual_bar .btn .play {position: relative;left: 3px;color: #fff;width: 8px;height: 8px;border-left: 8px solid #fff;border-top: 8px solid transparent;border-right: 8px solid transparent;border-bottom: 8px solid transparent}
.visual_bar .btn .play i {color: #fff}
.visual_bar .paging_btn {display: flex;align-items: center;margin-left: 50px}
.visual_bar .paging_btn button {background: none}
.visual_bar .visual_nextbtn,
.visual_bar .visual_prevbtn {position: static;margin: 0;width: auto;height: auto;color: #fff;font-size: 18px;opacity: 0.5;transition: all .3s}
.visual_bar .visual_prevbtn svg, .visual_bar .visual_nextbtn svg {margin-top: 10px;width: 30px;height: 30px;fill: #fff}
.visual_bar .visual_page {position: static;color: #ffffff;font-size: 18px;font-weight: 700;margin: 0 30px}
.visual_bar .visual_page p {opacity: .5;display: inline-block;margin-left: 10px}
.visual_bar .visual_page span {opacity: 1}
.visual_bar .visual_page .swiper-pagination-current {opacity: 1}

.visual_mask {position: absolute;left: 0;top: 0;bottom: 0;right: 0;background: #000;opacity: .3;width: 100%;height: 100vh}
@keyframes visual_text_motion {
    from {
        opacity: 0;
        filter: Alpha(opacity=0);
        -webkit-transform: translateY(10rem);
        transform: translateY(10rem);
    }
    to {
        opacity: 1.0;
        filter: Alpha(opacity=100);
        -webkit-transform: translateY(0);
        transform: translateY(0);
    }
}

@keyframes visual_text_motion2 {
    from {
        opacity: 0;
        filter: Alpha(opacity=0);
        -webkit-transform: translateY(10rem);
        transform: translateY(10rem);
    }
    to {
        opacity: 1.0;
        filter: Alpha(opacity=100);
        -webkit-transform: translateY(0);
        transform: translateY(0);
    }
}


/* solution */
.main_solution {height: 100vh;position: relative}
.main_solutionbox {transition: all 0.8s cubic-bezier(0.215, 0.61, 0.355, 1);position: absolute;left: 0;top: 0;width: 100%;height: 100%;opacity: 0;}
.main_solutionbox.active {opacity: 1;}
.main_solutionbox .txt {align-items: flex-start;width: 100%;max-width: 1630px;margin: 0 auto;display: flex;justify-content: center;height: 100vh;flex-direction: column;color: #fff}
.main_solutionbox .txt em {font-size: 20px;font-family: GSans}
.main_solutionbox .txt p {font-size: 40px;font-weight: 600;font-family: GSans}
.main_solutionbox .txt a {margin-top: 30px;color: #fff;display: inline-block;padding: 20px 50px;border: 2px solid #fff;border-radius: 30px;z-index: 0;}
.active.main_solutionbox .txt a {z-index: 10;}
.main_solutionbox.s01 {background: url(./assets/img/main/s01.png) no-repeat center center/ cover}
.main_solutionbox.s02 {background: url(./assets/img/main/visual_01.png) no-repeat center center/ cover}
.main_solutionbox.s03 {background: url(./assets/img/main/s03.png) no-repeat center center/ cover}
.main_solutionbox.s04 {background: url(./assets/img/main/s04.png) no-repeat center center/ cover}
.main_solutionbox.s05 {background: url(./assets/img/main/s05.png) no-repeat center center/ cover}
.main_solution ul {z-index: 9;padding-top: 150px;height: 100%;display: flex;position: absolute;right: 0;top: 0;width: 40%;border-left: 1px solid rgba(255, 255, 255, 0.3);flex-direction: column;justify-content: space-around}
.main_solution ul li {flex-direction: column;padding-left: 30px;opacity: .4;color: #fff;transition: all 0.3s ease;border-bottom: 1px solid rgba(255, 255, 255, 0.3);height: 100%;display: flex;justify-content: center}
.main_solution ul li p {font-size: 28px;}
.main_solution ul li span {font-size: 14px;display: block;margin-bottom: 10px}
.main_solution ul li:hover, .main_solution ul li.active {opacity: 1;}
.main_solution li.on, .main_solution > li.on::after {opacity: 1;}
/* cloud care */
.cloudcare_wrap {display: flex;height: 100vh;position: relative;align-items: center;color: #fff}
.cloudcare_wrap > div {display: flex;flex-direction: column;justify-content: center;align-items: center;height: 100vh;flex-basis: 50%;text-align: center;}
.cloudcare_wrap > div h3 {font-size: 30px}
.cloudcare_wrap > div p {padding: 20px 0 50px;font-size: 17px}
.cloudcare_wrap > div a {display: inline-block;padding: 15px 50px;border: 2px solid #fff;border-radius: 40px;color: #fff;transition: all 0.3s cubic-bezier(0.22, 0.61, 0.36, 1)}
.cloudcare_wrap > div a:hover {background: #fff;color: #000;border: 2px solid #fff;text-decoration: none;transition: all 0.3s cubic-bezier(0.22, 0.61, 0.36, 1)}
.cloudcare_wrap .ai {background: #252525 url(./assets/img/main/ai_bg1.png) no-repeat center center / cover;transition: all 0.3s ease-in}
.cloudcare_wrap .ai:hover {background: #64407e url(./assets/img/main/ai_bg2.png) no-repeat center center / cover;transition: all 0.3s ease-in-out}
.cloudcare_wrap .t {background: #252525 url(./assets/img/main/t_bg1.png) no-repeat center center / cover;transition: all 0.3s ease-in}
.cloudcare_wrap .t:hover {background: #f1850a url(./assets/img/main/t_bg2.png) no-repeat center center / cover;transition: all 0.3s ease-in-out}

/* news, partner,contact 공통*/
.box_1630 {width: 1630px;margin: 0 auto;display: flex;}
.main_titbox {flex-basis: 20%}
.main_titbox.type2 {flex-basis: 35%}
.main_titbox h3.h3_main {font-size: 15px;font-weight: 600;color: #ff951c;}
.main_titbox h4.h4_main {font-size: 35px;font-weight: 600;color: #000;line-height: 45px;margin: 20px 0 30px;font-family: GSans}
.main_titbox .arrow {display: flex;margin-bottom: 30px}
.main_titbox .arrow button {opacity: .3;margin-right: 10px;display: block;border: 1px solid #444;width: 60px;height: 60px;border-radius: 50%}
.main_titbox .arrow button.arrow_l {background: url(./assets/img/main/arrow_l.png) no-repeat center center}
.main_titbox .arrow button.arrow_r {background: url(./assets/img/main/arrow_r.png) no-repeat center center}
.main_titbox .arrow button:hover {opacity: 1}
/* .main_titbox .arrow a {opacity: .3;margin-right: 10px;display: block;border: 1px solid #444;width: 60px;height: 60px;border-radius: 50%}
.main_titbox .arrow a.arrow_l {background: url(./assets/img/main/arrow_l.png) no-repeat center center}
.main_titbox .arrow a.arrow_r {background: url(./assets/img/main/arrow_r.png) no-repeat center center}
.main_titbox .arrow a:hover {opacity: 1} */
.main_contbox {flex: 1}

/* news */
.main_news {height: 100vh;display: flex;align-items: center}
.main_news .main_titbox {margin-top: 100px}
.main_news .main_news_list {width: 1270px;overflow-x: scroll}
.main_news .main_news_list::-webkit-scrollbar {height: 3px}
.main_news .main_news_list::-webkit-scrollbar-thumb {background: #000000;border-radius: 3px}
.main_news .main_news_list::-webkit-scrollbar-track {background: #e3e3e3}
.main_news .main_news_list ul {display: flex;margin-bottom: 50px}
.main_news .main_news_list ul li {width: 33%;margin-right: 25px}
.main_news .main_news_list ul li .imgbox {width: 400px;height: 350px;overflow: hidden;display: flex;justify-content: center}
.main_news .main_news_list ul li .imgbox img {min-height: 100%;}
.main_news .main_news_list ul li p {padding: 20px 50px 20px 0}

/* partner */
.main_partner {height: 100vh;display: flex;align-items: center;background: #09161e url(./assets/img/main/partner_bg.png) no-repeat left bottom}
.main_partner .main_titbox h4 {color: #fff}
.main_partner .partner_list ul {display: flex;flex-wrap: wrap}
.main_partner .partner_list ul li {width: 23%;background: #fff;margin: 1%;text-align: center;display: flex;align-items: center;justify-content: center;height: 140px}
.main_partner .partner_list ul li img {width: 55%;}


/* contact */
.main_contact {height: 100vh;display: flex;align-items: center}
.footer_info {position: relative;display: flex;flex-direction: column;justify-content: space-between}
.footer_info .copyright {margin-top: 100px}
.footer_info li {position: relative;padding: 5px 10px 5px 25px;line-height: 25px}
.footer_info li:before {content: '';position: absolute;left: 0;top: 0;width: 30px;height: 30px}
.footer_info li.add {background: url(./assets/img/main/icon_address.png) no-repeat left 10px}
.footer_info li.add span{cursor: pointer;}
.footer_info li.add span:hover{text-decoration: underline; font-weight: bold;}
.footer_info li.tel {background: url(./assets/img/main/icon_call.png) no-repeat left center}
.footer_info li.ceo {background: url(./assets/img/main/icon_ceo.png) no-repeat left center}
.footer_info .privacy {display: flex}
.footer_info .privacy li {padding: 0;}
.footer_info .privacy li:first-child button{font-weight:600;color: #f12626}
.footer_info .privacy li button {background: none;}
.footer_info .privacy li:nth-child(2) button {padding: 0 40px}
.family_site {position: relative}
.family_site button {margin: 30px 0;background: none;font-size: 14px;font-weight: 700;color: #333}
.family_site .family_ul {top: 55px;z-index: 9;background: #fff;margin-top: 10px;position: absolute;left: 0;width: 180px;text-align: left;border: 2px solid #333;border-radius: 10px;}
.family_site .family_ul li a {display: block}
.main_contact .mapbox {display: flex;}
.main_contact .mapbox > div {width: 100%;margin: 0 1%;background: #333;height: 500px}
/* page dot */
.page_dot span {display: block;position: absolute;right: 10px;transform: rotate(-90deg)}
#top_button {background: none;color: #c0c0c0}

/* common */
.emphasis{color: #e02525;}




/* mobile */
@media (min-width: 280px) and (max-width: 630px){
    header{width:100%;padding:0 10px}
    header .header_menu{display:none}
    .header_wrap .logo{background-size:100px !important;}

    .visual_wrap{height:500px}
    .visual_wrap video{width:fit-content}
    .visual_wrap .visual_txt .txtbox h3 em{font-size:25px}
    .visual_wrap .visual_txt .txtbox h4{font-size:40px}
    .visual_wrap .visual_txt .visual_txtinnerbox{width:100%;left:auto;margin:0}
    .visual_wrap .visual_txt .txtbox h3 em{font-size:16px}
    .visual_wrap .visual_txt .txtbox h4{font-size:20px}
    .visual_wrap .visual_txt .txtbox h4 span:nth-child(2) em{font-size:30px}
    .visual_wrap .visual_txt .visual_txtinnerbox{bottom:5vh;height:250px;padding:0 20px}
    .page_dot{display:none}

    .header_right{display:flex}
    .header_right > div{height:30px;margin-right:10px}
    .header_wrap .login{position: relative;right:18px}
    .header_wrap:hover .btn-bar .bar, .header_wrap:hover .btn-bar::before, .header_wrap:hover .btn-bar::after {background-color:#333}
    .btn-bar {top: 20px;display: block;position: absolute;right:5px;width: 30px;height: 22px;font-size: 0;text-align: right;border: 0;background: none;}
   .btn-bar::before {top: 0;content: "";position: absolute;left: 0;width: 100%;height: 2px;background-color: #ffffff;transition: background-color 0.3s;}
   .btn-bar::after {bottom: 0;content: "";position: absolute;left: 0;width: 100%;height: 2px;background-color: #fff;transition: background-color 0.3s;}
   .btn-bar .bar {display: inline-block;width: 20px;height: 2px;vertical-align: middle;background-color: #fff;transition: width 0.3s, background-color 0.3s;}

    /* 전체메뉴 */
    .all_menu{background:#333;position:fixed;display: flex;top: 0;right: 0;width:100%;height:100vh;z-index: 999;transition: transform 0.3s, visibility 0.3s, opacity 0.3s}
    .all_menu .all_menu_in{width:1500px;margin:0 auto;display: flex;height: 100%;position: relative}
    .all_menu .all_menu_close{display: block;position: absolute;right: 10px;top:22px;width:30px;height:30px;border:none;background:none;z-index: 100;}
    .all_menu .all_menu_close::before, .all_menu .all_menu_close::after{content: "";position: absolute;top:50%;left:0;width:100%;height:2px ;margin-top:-1px;background: #ffffff;transition:transform 0.3s }
    .all_menu_close:hover::before{transform:rotate(45deg);}
    .all_menu_close:hover::after{transform:rotate(-45deg);}
    .all_menu .inner{width: 100%;margin: 0 auto;padding:50px 10px ;position: relative;max-width: 1640px;height: 100%;z-index: 10;}
    .all_menu .header_2depth{box-shadow:none;position: absolute;right:0;width:240px;height:auto;left:auto;top:43px}
    .all_menu .header_2depth > ul{ width: 100%}
    .all_menu .header_2depth > ul li{display:block}
    .all_menu .header_3depth{position: relative;top:0;width:100%}
    .all_menu .header_3depth ul li{display:block}
    .all_menu .header_3depth ul li a{display:block;width:100%;font-size:14px;padding:7px 0}
    .all_menu .inner > ul > li > a{opacity: .6;font-size:19px;font-weight:700;padding:20px 10px;display:inline-block;width:100px}
    .all_menu ul li a.link_active{opacity:1;background:#fff;color:#333 !important;}


    .gnb_login{position: absolute;right:80px;top:55px}
    .gnb_login a{color:#fff;display:inline-block;padding:12px 30px 12px 60px;border:1px solid #acacac;border-radius:40px}
    .gnb_login a:hover{text-decoration: none;border:1px solid #fff}
    .inner ul li a{color:#fff !important;}



    .box_1630{flex-direction: column;padding:0 10px;width:100%}

    .main_solution{margin:10px 0;height:400px}
    .main_solutionbox .txt{padding:170px 15px 10px;height:auto}
    .main_solutionbox .txt p{font-size:18px}
    .main_solution ul{width:100%;flex-direction: row;height:90px;padding:10px 0}
    .main_solution ul li{width:20%;padding:3px;text-align:center;}
    .main_solution ul li:hover, .main_solution ul li.active{border-bottom:2px solid #fff}
    .main_solution ul li p{font-size:13px}
    .main_solution ul li span{display:none}

    .cloudcare_wrap{flex-direction: column}
    .cloudcare_wrap > div{height:auto;width:100%}

    .main_news{height:auto;margin-bottom:20px}
    .main_news .main_news_list{width:100%}
    .main_news .main_news_list ul li{width:auto}
    .main_news .main_news_list ul li .imgbox{width:250px;height:200px}
    .main_news .main_contbox{overflow:hidden}
    .main_news .more{display:block;margin-bottom:20px}

    .main_partner{height:auto;padding:100px 0}
    .main_partner .partner_list ul li{width:31%}
    .main_partner .partner_list ul li img{width:80%}

    .main_contact{height:auto}
    .main_contact .mapbox > div{height:250px;margin-bottom:20px}
    .main_contact .mapbox > div #map{height:250px !important;}

    .footer_info .copyright{margin:20px 0}

}



/* tablet */
@media (min-width: 631px) and (max-width: 1024px){
    header{width:100%;padding:0 10px}
    header .header_menu{display:none}
    .header_wrap .logo{background-size:100px !important;}

    .visual_wrap{height:500px}
    .visual_wrap video{width:fit-content}
    .visual_wrap .visual_txt .txtbox h3 em{font-size:25px}
    .visual_wrap .visual_txt .txtbox h4{font-size:40px}
    .visual_wrap .visual_txt .visual_txtinnerbox{width:100%;left:auto;margin:0}
    .visual_wrap .visual_txt .txtbox h3 em{font-size:16px}
    .visual_wrap .visual_txt .txtbox h4{font-size:20px}
    .visual_wrap .visual_txt .txtbox h4 span:nth-child(2) em{font-size:30px}
    .visual_wrap .visual_txt .visual_txtinnerbox{bottom:5vh;height:250px;padding:0 20px}
    .page_dot{display:none}

    .header_right{display:flex}
    .header_right > div{width:40px;height:30px}
    .header_wrap:hover .btn-bar .bar, .header_wrap:hover .btn-bar::before, .header_wrap:hover .btn-bar::after {background-color:#333}
    .btn-bar {top: 20px;display: block;position: absolute;right:5px;width: 30px;height: 22px;font-size: 0;text-align: right;border: 0;background: none;}
    .btn-bar::before {top: 0;content: "";position: absolute;left: 0;width: 100%;height: 2px;background-color: #ffffff;transition: background-color 0.3s;}
    .btn-bar::after {bottom: 0;content: "";position: absolute;left: 0;width: 100%;height: 2px;background-color: #fff;transition: background-color 0.3s;}
    .btn-bar .bar {display: inline-block;width: 20px;height: 2px;vertical-align: middle;background-color: #fff;transition: width 0.3s, background-color 0.3s;}

    /* 전체메뉴 */
    .all_menu{background:#333;position:fixed;display: flex;top: 0;right: 0;width:100%;height:100vh;z-index: 999;transition: transform 0.3s, visibility 0.3s, opacity 0.3s}
    .all_menu .all_menu_in{width:1500px;margin:0 auto;display: flex;height: 100%;position: relative}
    .all_menu .all_menu_close{display: block;position: absolute;right: 10px;top:22px;width:30px;height:30px;border:none;background:none;z-index: 100;}
    .all_menu .all_menu_close::before, .all_menu .all_menu_close::after{content: "";position: absolute;top:50%;left:0;width:100%;height:2px ;margin-top:-1px;background: #ffffff;transition:transform 0.3s }
    .all_menu_close:hover::before{transform:rotate(45deg);}
    .all_menu_close:hover::after{transform:rotate(-45deg);}
    .all_menu .inner{width: 100%;margin: 0 auto;padding:50px 10px ;position: relative;max-width: 1640px;height: 100%;z-index: 10;}
    .all_menu .header_2depth{box-shadow:none;position: absolute;left:200px;width:240px;height:auto;top:43px}
    .all_menu .header_2depth > ul{ width: 100%}
    .all_menu .header_2depth > ul li{display:block}
    .all_menu .header_3depth{position: relative;top:0;width:100%}
    .all_menu .header_3depth ul li{display:block}
    .all_menu .header_3depth ul li a{display:block;width:100%;font-size:14px;padding:7px 0}
    .all_menu .inner > ul > li > a{opacity: .6;font-size:19px;font-weight:700;padding:20px 10px;display:inline-block;width:100px}
    .all_menu ul li a.link_active{opacity:1;background:#fff;color:#333 !important;}
    .all_menu ul li a.child_active{text-decoration: none;opacity: 1;font-weight: 400;}


    .gnb_login{position: absolute;right:80px;top:55px}
    .gnb_login a{color:#fff;display:inline-block;padding:12px 30px 12px 60px;border:1px solid #acacac;border-radius:40px}
    .gnb_login a:hover{text-decoration: none;border:1px solid #fff}
    .inner ul li a{color:#fff !important;}



    .box_1630{flex-direction: column;padding:0 10px;width:100%}

    .main_solution{margin:10px 0;height:400px}
    .main_solutionbox .txt{padding:170px 15px 10px;height:auto}
    .main_solutionbox .txt p{font-size:18px}
    .main_solution ul{width:100%;flex-direction: row;height:90px;padding:10px 0}
    .main_solution ul li{width:20%;padding:3px;text-align:center;}
    .main_solution ul li:hover, .main_solution ul li.active{border-bottom:2px solid #fff}
    .main_solution ul li p{font-size:13px}
    .main_solution ul li span{display:none}

    .cloudcare_wrap{height:500px}
    .cloudcare_wrap > div{height:500px}

    .main_news{height:auto;margin-bottom:20px}
    .main_news .main_news_list{width:100%}
    .main_news .main_news_list ul li{width:auto}
    .main_news .main_news_list ul li .imgbox{width:250px;height:200px}
    .main_news .main_contbox{overflow:hidden}
    .main_news .more{display:block;margin-bottom:20px}


    .main_contact{height:auto}
    .main_contact .mapbox > div{height:400px;margin-bottom:20px}
    .main_contact .mapbox > div #map{height:400px !important;}

    .footer_info .copyright{margin:20px 0}

}


/* desktop */
@media (min-width: 1025px) and (max-width: 1600px) {
    .header_2depth .img{display:none}
    .header_2depth > ul{width:auto}

    .visual_wrap video{width:auto}
    .visual_wrap{height:500px}
    .visual_wrap video{width:fit-content}
    .visual_wrap .visual_txt .txtbox h3 em{font-size:25px}
    .visual_wrap .visual_txt .txtbox h4{font-size:40px}
    .visual_wrap .visual_txt .visual_txtinnerbox{width:100%;left:auto;margin:0}
    .visual_wrap .visual_txt .txtbox h3 em{font-size:16px}
    .visual_wrap .visual_txt .txtbox h4{font-size:20px}
    .visual_wrap .visual_txt .txtbox h4 span:nth-child(2) em{font-size:30px}
    .visual_wrap .visual_txt .visual_txtinnerbox{bottom:5vh;height:250px;padding:0 20px}
    .page_dot{display:none}
    .box_1630{flex-direction: column;width:100%;padding:0 20px}

    .main_solution{margin:10px 0;height:800px}
    .main_solution ul{padding:0}
    .main_solutionbox .txt{height:800px;padding:0 500px 0 10px}

    .cloudcare_wrap{height:500px}
    .cloudcare_wrap > div{height:500px}

    .main_news{height:auto;margin-bottom:20px}
    .main_news .main_news_list{width:100%}
    .main_news .main_news_list ul li{width:auto}
    .main_news .main_news_list ul li .imgbox{width:250px;height:200px}
    .main_news .main_contbox{overflow:hidden}
    .main_news .more{display:block;margin-bottom:20px}







}
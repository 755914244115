@charset "utf-8";
@font-face {font-family:'nexon2'; font-style:normal;font-weight:200;font-display: swap; src: url(/src/assets/font/NEXON_Lv2_Gothic_Light.woff) format('woff'), url(/src/assets/font/NEXON_Lv2_Gothic_Light.otf) format('opentype');}
@font-face {font-family:'nexon2'; font-style:normal;font-weight:500;font-display: swap; src: url(/src/assets/font/NEXON_Lv2_Gothic.woff) format('woff'), url(/src/assets/font/NEXON_Lv2_Gothic.otf) format('opentype');}
@font-face {font-family:'nexon2'; font-style:normal;font-weight:700;font-display: swap; src:  url(/src/assets/font/NEXON_Lv2_Gothic_Medium.otf) format('opentype');}
@font-face {font-family:'nexon2'; font-style:normal;font-weight:900;font-display: swap; src: url(/src/assets/font/NEXON_Lv2_Gothic_Bold.woff) format('woff'), url(/src/assets/font/NEXON_Lv2_Gothic_Bold.otf) format('opentype');}
@font-face {font-family: 'GSans';  src: url('https://cdn.jsdelivr.net/gh/projectnoonnu/noonfonts_2001@1.1/GmarketSansMedium.woff') format('woff');  font-weight: 400;  font-style: normal; }
@font-face {font-family: 'GSans';  src: url('https://cdn.jsdelivr.net/gh/projectnoonnu/noonfonts_2001@1.1/GmarketSansLight.woff') format('woff');  font-weight: 100;  font-style: normal; }
@font-face {font-family: 'GSans';  src: url('https://cdn.jsdelivr.net/gh/projectnoonnu/noonfonts_2001@1.1/GmarketSansBold.woff') format('woff');  font-weight: 700;  font-style: normal; }


@font-face {  font-family: 'Pretendard';  font-weight: 900;  font-display: swap;  src: local('Pretendard Black'), url('/src/assets/font/pretendard/Pretendard-Black.woff2') format('woff2'), url('/src/assets/font/pretendard/Pretendard-Black.woff') format('woff');  }
@font-face {  font-family: 'Pretendard';  font-weight: 800;  font-display: swap;  src: local('Pretendard ExtraBold'), url('/src/assets/font/pretendard/Pretendard-ExtraBold.woff2') format('woff2'), url('/src/assets/font/pretendard/Pretendard-ExtraBold.woff') format('woff');  }
@font-face {  font-family: 'Pretendard';  font-weight: 700;  font-display: swap;  src: local('Pretendard Bold'), url('/src/assets/font/pretendard/Pretendard-Bold.woff2') format('woff2'), url('/src/assets/font/pretendard/Pretendard-Bold.woff') format('woff');  }
@font-face {  font-family: 'Pretendard';  font-weight: 600;  font-display: swap;  src: local('Pretendard SemiBold'), url('/src/assets/font/pretendard/Pretendard-SemiBold.woff2') format('woff2'), url('/src/assets/font/pretendard/Pretendard-SemiBold.woff') format('woff');  }
@font-face {  font-family: 'Pretendard';  font-weight: 500;  font-display: swap;  src: local('Pretendard Medium'), url('/src/assets/font/pretendard/Pretendard-Medium.woff2') format('woff2'), url('/src/assets/font/pretendard/Pretendard-Medium.woff') format('woff');  }
@font-face {  font-family: 'Pretendard';  font-weight: 400;  font-display: swap;  src: local('Pretendard Regular'), url('/src/assets/font/pretendard/Pretendard-Regular.woff2') format('woff2'), url('/src/assets/font/pretendard/Pretendard-Regular.woff') format('woff');  }
@font-face {  font-family: 'Pretendard';  font-weight: 300;  font-display: swap;  src: local('Pretendard Light'), url('/src/assets/font/pretendard/Pretendard-Light.woff2') format('woff2'), url('/src/assets/font/pretendard/Pretendard-Light.woff') format('woff');  }
@font-face {  font-family: 'Pretendard';  font-weight: 200;  font-display: swap;  src: local('Pretendard ExtraLight'), url('/src/assets/font/pretendard/Pretendard-ExtraLight.woff2') format('woff2'), url('/src/assets/font/pretendard/Pretendard-ExtraLight.woff') format('woff');  }
@font-face {  font-family: 'Pretendard';  font-weight: 100;  font-display: swap;  src: local('Pretendard Thin'), url('/src/assets/font/pretendard/Pretendard-Thin.woff2') format('woff2'), url('/src/assets/font/pretendard/Pretendard-Thin.woff') format('woff');  }


/* Reset */
* {margin:0;padding:0;box-sizing:border-box}
html {font-size:62.5%;}
html{-webkit-touch-callout:none; -webkit-tap-highlight-color:rgba(0, 0, 0, 0);}
article, aside, details, figcaption, figure, footer, header, hgroup, menu, nav, section {display:block}
html,body,h1,h2,h3,h4,h5,h6,div,p,blockquote,pre,code,address,ul,ol,li,menu,nav,section,article,aside,dl,dt,dd,table,thead,tbody,tfoot,label,
caption,th,td,form,fieldset,legend,hr,input,button,textarea,object,figure,figcaption {margin:0; padding:0; border:0 none}
body{width:100%; background:#fff;-webkit-text-size-adjust:none;word-wrap:break-word;word-break:break-all;max-width:2560px;min-width:280px;height:100%}
body,input,select,textarea,button {border:none;font-size:1.3rem;font-family:'Pretendard','Malgun Gothic','Dotum','Gulim','Tahoma','Verdana',sans-serif;color:#777;font-weight:500}
input {font-family:'Pretendard','Malgun Gothic','Dotum','Gulim','Tahoma','Verdana',sans-serif;font-weight:500}
header, h1 {font-size:100%}
fieldset{border:0 none !important;}
html, body {height:100%}
main,header,section,nav,footer,aside,article,figure{display:block}
div, p, span, li, table td {word-break:keep-all}
caption,legend {overflow:hidden;position:absolute;width:1px;height:1px;font-size:0;line-height:0}
img {border:none;vertical-align:top;/*width:100%;*/ }
select, input {vertical-align:middle}
hr {display:none;border:0 none}
ol, ul, ul li{list-style:none}
address,cite,code,em{font-style:normal;font-weight:normal}
label{cursor:pointer}
button{border:0;cursor:pointer}

#boardViewTxt img{width: 500px; object-fit: cover;}
/* link */
a{color:#000;text-decoration:none} /*링크 기본*/
a:link, a:visited {text-decoration:none}
a:hover, a:active, a:focus {text-decoration:underline}


/* table */
table {width:100%;border-collapse:collapse} /*테이블 기본*/
table, th, td {border:0;border-spacing:0;border-style:none}

/* float */
.fl{float:left} /* Flot 왼쪽정렬*/
.fr{float:right} /* Flot 오른쪽정렬*/


/* text-align */
.txtr{text-align:right !important} /*텍스트 오른쪽정렬*/
.txtl{text-align:left !important}  /*텍스트 왼쪽정렬*/
.txtc{text-align:center !important} /*텍스트 가운데정렬*/

/* hidden */
.hidden {overflow:hidden;position:absolute;width:1px;height:1px;font-size:0;line-height:0} /*폰트, 타이틀 감추기 */
.hidden,.hide{overflow:hidden;position:absolute;width:1px;height:1px;font-size:0;line-height:0} /*폰트, 타이틀 감추기 */

/* clear */
.cboth{clear:both} /* 정렬 초기화 */

/* Skip Menu */
.skipMenu{position:relative;z-index:9999} /*스킵 메뉴 */
.skipMenu a{display:block;height:1px;width:1px;margin-bottom:-1px;overflow:hidden;font-size:13px;
    color:#fff;font-weight:300;letter-spacing:-1px;background:#545e72;
    white-space:nowrap;text-align:center;text-decoration:none;max-width:2560px}
.skipMenu a:focus, .skipMenu a:active{height:auto;width:100%;padding:5px;margin-bottom:10px;position:absolute;left:0;top:0}

/* input */
input[type="text"],input[type="password"]{padding:0 5px;font-size:14px;height:40px;line-height:38px;
   border-radius:0;vertical-align:
        middle;box-sizing:border-box;box-shadow:none} /*input 기본값 */
.input_type{padding:0 5px;font-size:14px;height:40px;line-height:38px;background:#fff;border:1px solid #ddd;
    border-radius:0;vertical-align:middle;box-sizing:border-box}
label.label_type1{vertical-align:middle;color:#494949;font-size:15px;padding:2px 0 2px 0;display:inline-block;
    line-height:21px;position:relative}
label.lab_wid1{min-width:192px;padding-bottom:8px}
label.rid_lab{vertical-align:middle;color:#494949;font-size:15px; padding:0 0 0 32px;position:relative;
    margin-right:5px;line-height:28px}
input[type=radio]{width:13px;height:13px;margin-right:4px} /*input radio 버튼기본값 */
input[type="checkbox"]{width:14px;height:14px;margin-right:4px} /*input check */
input:disabled{background:#eee}  /*input 사용하지않음 */

/* select */

.select_type{border-radius:0;border:1px solid #ddd;min-width:100px;font-size:14px;letter-spacing:-.04em;color:#666;font-weight:400
;text-align:left;vertical-align:top;height:40px;
    line-height:38px;padding:0 6px;background:#fff right top no-repeat;text-indent:3px}

a[href^=tel] {
    text-decoration:inherit;
    color:inherit;
}

.board_search_form{}

.no_data_type1{width:100%;text-align:center;padding:40px 0;border-top:1px solid #ddd;border-bottom:1px solid #ddd}

/*.swiper-wrapper{transition-timing-function: ease-out;position: relative;display: flex;transform-style: preserve-3d;transition-property: transform;box-sizing: content-box;}*/
/*.swiper-slide{ display:inline-block;flex-shrink: 0;}*/
/*.swiper-slide:not(.swiper-slide-active) {
    opacity: 0 !important;
}*/

#contents_wrap { overflow:hidden; width:100%; height:auto; }
/* width */
.w50{width:50px}
.w150{width:150px}
.w200{width:200px}
.w250{width:250px}
.w300{width:300px}
.w350{width:350px}
.w400{width:400px}
.w450{width:450px}
.w500{width:500px}
.wfull_95{width:95%}
.wfull{width:100%}

.w100per{width:100% !important}
.w10per{width:10% !important}
.w50per{width:50% !important}

/* margin */
.mt0{margin-top:0 !important}
.mt5{margin-top:5px !important}
.mt10{margin-top:10px !important}
.mt15{margin-top:15px !important}
.mt20{margin-top:20px !important}
.mt25{margin-top:25px !important}
.mt30{margin-top:30px !important}
.mt35{margin-top:35px !important}
.mt40{margin-top:40px !important}
.mt45{margin-top:45px !important}
.mt50{margin-top:50px !important}
.mt55{margin-top:55px !important}
.mt60{margin-top:60px !important}
.mt65{margin-top:65px !important}
.mt70{margin-top:70px !important}
.mt75{margin-top:75px !important}
.mt80{margin-top:80px !important}
.mt85{margin-top:85px !important}
.mt90{margin-top:90px !important}
.mt95{margin-top:95px !important}
.mt100{margin-top:100px !important}
.mt150{margin-top:150px !important}

.mb0{margin-bottom:0 !important}
.mb5{margin-bottom:5px !important}
.mb10{margin-bottom:10px !important}
.mb15{margin-bottom:15px !important}
.mb20{margin-bottom:20px !important}
.mb25{margin-bottom:25px !important}
.mb30{margin-bottom:30px !important}
.mb35{margin-bottom:35px !important}
.mb40{margin-bottom:40px !important}
.mb45{margin-bottom:45px !important}
.mb50{margin-bottom:50px !important}
.mb55{margin-bottom:55px !important}
.mb60{margin-bottom:60px !important}
.mb65{margin-bottom:65px !important}
.mb70{margin-bottom:70px !important}
.mb75{margin-bottom:75px !important}
.mb80{margin-bottom:80px !important}
.mb85{margin-bottom:85px !important}
.mb90{margin-bottom:90px !important}
.mb95{margin-bottom:95px !important}
.mb100{margin-bottom:100px !important}

.ml0{margin-left:0 !important}
.ml5{margin-left:5px !important}
.ml10{margin-left:10px !important}
.ml15{margin-left:15px !important}
.ml20{margin-left:20px !important}
.ml25{margin-left:25px !important}
.ml30{margin-left:30px !important}
.ml35{margin-left:35px !important}
.ml40{margin-left:40px !important}
.ml45{margin-left:45px !important}
.ml50{margin-left:50px !important}
.ml55{margin-left:55px !important}
.ml60{margin-left:60px !important}
.ml65{margin-left:65px !important}
.ml70{margin-left:70px !important}
.ml75{margin-left:75px !important}
.ml80{margin-left:80px !important}
.ml85{margin-left:85px !important}
.ml90{margin-left:90px !important}
.ml95{margin-left:95px !important}
.ml100{margin-left:100px !important}

.mr0{margin-right:0 !important}
.mr5{margin-right:5px !important}
.mr10{margin-right:10px !important}
.mr15{margin-right:15px !important}
.mr20{margin-right:20px !important}
.mr25{margin-right:25px !important}
.mr30{margin-right:30px !important}
.mr35{margin-right:35px !important}
.mr40{margin-right:40px !important}
.mr45{margin-right:45px !important}
.mr50{margin-right:50px !important}
.mr55{margin-right:55px !important}
.mr60{margin-right:60px !important}
.mr65{margin-right:65px !important}
.mr70{margin-right:70px !important}
.mr75{margin-right:75px !important}
.mr80{margin-right:80px !important}
.mr85{margin-right:85px !important}
.mr90{margin-right:90px !important}
.mr95{margin-right:95px !important}
.mr100{margin-right:100px !important}

/* padding */
.pt0{padding-top:0 !important}
.pt5{padding-top:5px !important}
.pt10{padding-top:10px !important}
.pt15{padding-top:15px !important}
.pt20{padding-top:20px !important}
.pt25{padding-top:25px !important}
.pt30{padding-top:30px !important}
.pt35{padding-top:35px !important}
.pt40{padding-top:40px !important}
.pt45{padding-top:45px !important}
.pt50{padding-top:50px !important}
.pt55{padding-top:55px !important}
.pt60{padding-top:60px !important}
.pt65{padding-top:65px !important}
.pt70{padding-top:70px !important}
.pt75{padding-top:75px !important}
.pt80{padding-top:80px !important}
.pt85{padding-top:85px !important}
.pt90{padding-top:90px !important}
.pt95{padding-top:95px !important}
.pt100{padding-top:100px !important}

.pb0{padding-bottom:0 !important}
.pb5{padding-bottom:5px !important}
.pb10{padding-bottom:10px !important}
.pb15{padding-bottom:15px !important}
.pb20{padding-bottom:20px !important}
.pb25{padding-bottom:25px !important}
.pb30{padding-bottom:30px !important}
.pb35{padding-bottom:35px !important}
.pb40{padding-bottom:40px !important}
.pb45{padding-bottom:45px !important}
.pb50{padding-bottom:50px !important}
.pb55{padding-bottom:55px !important}
.pb60{padding-bottom:60px !important}
.pb65{padding-bottom:65px !important}
.pb70{padding-bottom:70px !important}
.pb75{padding-bottom:75px !important}
.pb80{padding-bottom:80px !important}
.pb85{padding-bottom:85px !important}
.pb90{padding-bottom:90px !important}
.pb95{padding-bottom:95px !important}
.pb100{padding-bottom:100px !important}

.pl0{padding-left:0 !important}
.pl5{padding-left:5px !important}
.pl10{padding-left:10px !important}
.pl15{padding-left:15px !important}
.pl20{padding-left:20px !important}
.pl25{padding-left:25px !important}
.pl30{padding-left:30px !important}
.pl35{padding-left:35px !important}
.pl40{padding-left:40px !important}
.pl45{padding-left:45px !important}
.pl50{padding-left:50px !important}
.pl55{padding-left:55px !important}
.pl60{padding-left:60px !important}
.pl65{padding-left:65px !important}
.pl70{padding-left:70px !important}
.pl75{padding-left:75px !important}
.pl80{padding-left:80px !important}
.pl85{padding-left:85px !important}
.pl90{padding-left:90px !important}
.pl95{padding-left:95px !important}
.pl100{padding-left:100px !important}

.pr0{padding-right:0 !important}
.pr5{padding-right:5px !important}
.pr10{padding-right:10px !important}
.pr15{padding-right:15px !important}
.pr20{padding-right:20px !important}
.pr25{padding-right:25px !important}
.pr30{padding-right:30px !important}
.pr35{padding-right:35px !important}
.pr40{padding-right:40px !important}
.pr45{padding-right:45px !important}
.pr50{padding-right:50px !important}
.pr55{padding-right:55px !important}
.pr60{padding-right:60px !important}
.pr65{padding-right:65px !important}
.pr70{padding-right:70px !important}
.pr75{padding-right:75px !important}
.pr80{padding-right:80px !important}
.pr85{padding-right:85px !important}
.pr90{padding-right:90px !important}
.pr95{padding-right:95px !important}
.pr100{padding-right:100px !important}


/****** fold Device ******/
@media screen and (min-width:280px) and (max-width:319px) {

    html {font-size:55.5% !important;}


}


/****** Mobile ******/
@media screen and (max-width:690px){
    html {font-size:62.5%;}


}




